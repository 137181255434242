code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Accidental-Presidency";
  font-style: normal;
  src: local("Accidental-Presidency"),
    url(./fonts/Accidental-Presidency.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-BoldCondensed";
  src: local("Roboto-BoldCondensed"),
    url(./fonts/Roboto-BoldCondensed.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Accidental-Presidency", "Roboto-BoldCondensed";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
